
<template>
  <CardComponent class="has-table" title="その他給設定" hasButtonSlot>
    <b-button
      v-if="editable"
      slot="button"
      type="is-info"
      size="is-small"
      outlined
      @click="willToForm(null)"
      >追加</b-button
    >
    <b-table :loading="loading || isLoading" hoverable :data="tBonuses">
      <b-table-column label="" v-slot="{ index }" width="3em">
        <small class="has-text-grey">{{ index + 1 }}</small>
      </b-table-column>
      <b-table-column label="管理番号" v-slot="{ row }" width="6em">
        <span v-if="row.mInstructor">#{{ row.mInstructor.code }}</span>
        <soan v-else />
      </b-table-column>
      <b-table-column label="氏名" v-slot="{ row }" width="10em">
        <span v-if="row.mInstructor">{{ row.mInstructor.name }}</span>
        <soan v-else />
      </b-table-column>
      <b-table-column label="エリア" v-slot="{ row }" width="6em">
        <span v-if="row.area">{{ row.area.label }}</span>
        <soan v-else />
      </b-table-column>
      <b-table-column label="備考" v-slot="{ row }">
        <span>{{ row.note }}</span>
      </b-table-column>
      <b-table-column label="その他給" v-slot="{ row }" numeric width="8em">
        <span>{{ row.amount.toLocaleString() }}円</span>
      </b-table-column>
      <b-table-column
        custom-key="actions"
        cell-class="is-actions-cell"
        v-slot="{ row }"
        width="4em"
        :visible="editable"
      >
        <div class="buttons is-right">
          <b-button
            type="is-warning"
            size="is-small"
            icon-left="square-edit-outline"
            @click="willToForm(row)"
          />
        </div>
      </b-table-column>
      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="loading || isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </CardComponent>
</template>

<script>
import BaseMixin from '@/baseMixin'
import { ApiBonus } from '@/api'
import CardComponent from '../CardComponent.vue'
import BonusFormVue from './BonusForm.vue'
import { Collection } from '@/util'
export default {
  components: { CardComponent },
  mixins: [BaseMixin],
  props: {
    loading: Boolean,
    paymentYm: String,
    editable: Boolean
  },
  data () {
    return {
      tBonuses: []
    }
  },
  methods: {
    willToForm (tBonus) {
      console.log(tBonus, 'willToForm')
      this.$buefy.modal.open({
        parent: this,
        component: BonusFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: {
          paymentYm: this.paymentYm,
          tBonus: tBonus || null },
        events: {
          saved: (tBonus) => {
            this.tBonuses = new Collection(this.tBonuses).set(tBonus).members
            this.alertSuccess('その他給を更新しました')
          },
          deleted: (deletedId) => {
            this.tBonuses = new Collection(this.tBonuses).remove(deletedId).members
            this.alertSuccess('その他給を削除しました')
          }
        }
      })
    },
  },
  mounted () {
    this.isLoading = true
    ApiBonus.fetchBonuses(this.paymentYm).then(({ tBonuses }) => {
      this.tBonuses = tBonuses
      this.isLoading = false
    }).catch(this.handleError)
  }
}
</script>

<style>
</style>