<template>
  <CardComponent class="has-table" title="インストラクター集計">
    <CardToolbar slot="toolbar">
      <div slot="left">
        <b-field v-if="tPaymentWork && tPaymentWork.fixStatus.is('Request')">
          <b-radio-button v-model="columnsType" native-value="details">
            <span>内訳</span>
          </b-radio-button>
          <b-radio-button v-model="columnsType" native-value="request">
            <span>請求書確認状況</span>
          </b-radio-button>
        </b-field>
      </div>
      <form slot="right">
        <div class="field has-addons">
          <b-input v-model="filterText" placeholder="検索" />
          <div class="control">
            <b-button type="is-light" @click="filterText = ''"
              ><b-icon icon="eraser" custom-size="default"
            /></b-button>
          </div>
        </div>
      </form>
    </CardToolbar>
    <b-table
      :loading="loading"
      hoverable
      narrowed
      :data="filterTInstructorSummarys"
      @select="(row) => willToRows(row)"
    >
      <b-table-column label="" v-slot="{ index }" width="3em">
        <small class="has-text-grey">{{ index + 1 }}</small>
      </b-table-column>
      <b-table-column label="管理番号" v-slot="{ row }" width="6em">
        <span>#{{ row.code }}</span>
      </b-table-column>
      <b-table-column label="氏名" v-slot="{ row }" width="8em">
        <span>{{ row.name }}</span>
      </b-table-column>
      <b-table-column
        label="実績数"
        v-slot="{ row }"
        numeric
        :visible="showDetails"
      >
        <span>{{ row.totalCount }}</span>
      </b-table-column>
      <b-table-column
        label="出勤日数"
        v-slot="{ row }"
        numeric
        :visible="showDetails"
      >
        <span>{{ row.targetDateCnt }}日</span>
      </b-table-column>
      <b-table-column label="出勤時間" v-slot="{ row }" numeric>
        <span>{{ row.hours.toLocaleString() }}h</span>
      </b-table-column>
      <b-table-column
        label="時間給"
        v-slot="{ row }"
        numeric
        :visible="showDetails"
      >
        <span>{{ row.baseAmount.toLocaleString() }}</span>
      </b-table-column>
      <b-table-column
        label="各種手当"
        v-slot="{ row }"
        numeric
        :visible="showDetails"
      >
        <span>{{ row.instillAmount.toLocaleString() }}</span>
      </b-table-column>
      <b-table-column
        label="固定額"
        v-slot="{ row }"
        numeric
        :visible="showDetails"
      >
        <span>{{ row.fixedAmount.toLocaleString() }}</span>
      </b-table-column>
      <b-table-column
        label="その他給"
        v-slot="{ row }"
        numeric
        :visible="showDetails"
      >
        <span>{{ row.bonusAmount.toLocaleString() }}</span>
      </b-table-column>
      <b-table-column
        label="合計"
        v-slot="{ row }"
        numeric
        width="8em"
        :visible="showDetails"
      >
        <span>{{ row.totalAmount.toLocaleString() }}円</span>
      </b-table-column>
      <b-table-column
        label="小計"
        v-slot="{ row }"
        numeric
        width="8em"
        :visible="showRequest"
      >
        <span>{{ row.totalAmount.toLocaleString() }}円</span>
      </b-table-column>
      <b-table-column
        label="源泉徴収税額"
        v-slot="{ row }"
        numeric
        width="10em"
        :visible="showRequest"
      >
        <span>{{ row.withholdingAmount.toLocaleString() }}円</span>
      </b-table-column>
      <b-table-column
        label="差引"
        v-slot="{ row }"
        numeric
        width="8em"
        :visible="showRequest"
      >
        <span>{{ row.taxedAmount.toLocaleString() }}円</span>
      </b-table-column>
      <b-table-column
        label="報告書確認"
        v-slot="{ row }"
        width="7em"
        centered
        :visible="showRequest"
      >
        <b-tag v-if="row.isBillingAnswerOk" type="is-success">確認済</b-tag>
        <b-tag v-else type="is-light">確認中</b-tag>
      </b-table-column>
      <b-table-column
        label="確認日時"
        v-slot="{ row }"
        width="12em"
        :visible="showRequest"
      >
        <span v-if="row.billingConfirmAt">{{
          row.billingConfirmAt.format('YYYY/MM/DD HH:mm:ss')
        }}</span>
        <span v-else />
      </b-table-column>
      <b-table-column label="" width="4em">
        <b-icon type="is-info" icon="chevron-right" />
      </b-table-column>
      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="loading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </CardComponent>
</template>

<script>
import CardComponent from '../CardComponent.vue'
import CardToolbar from '../CardToolbar.vue'
import { TInstructorSummary, TPaymentWork } from '@/types/typeWork' // eslint-disable-line no-unused-vars
export default {
  components: { CardComponent, CardToolbar },
  props: {
    loading: Boolean,
    tPaymentWork: TPaymentWork,
    tInstructorSummarys: Array
  },
  computed: {
    filterTInstructorSummarys () {
      if (!this.filterText) {
        return this.tInstructorSummarys
      }
      return this.tInstructorSummarys.filter(row => row.searchText.includes(this.filterText))
    },
    showDetails () {
      return this.columnsType === 'details'
    },
    showRequest () {
      return this.columnsType === 'request'
    },
  },
  data () {
    return {
      columnsType: 'details',
      filterText: ''
    }
  },
  methods: {
    /**
     * @param {TInstructorSummary} tInstructorSummary
     */
    willToRows (tInstructorSummary) {
      this.$router.push({ name: 'PaymentInstructor', params: { pathInstructorId: tInstructorSummary.id.toString() } })
    }
  },
  watch: {
    tPaymentWork (val) {
      this.columnsType = val && val.fixStatus.is('Request') ? 'request' : 'details'
    }
  }
}
</script>

<style>
</style>