var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CardComponent',{staticClass:"has-table",attrs:{"title":"その他給設定","hasButtonSlot":""}},[(_vm.editable)?_c('b-button',{attrs:{"slot":"button","type":"is-info","size":"is-small","outlined":""},on:{"click":function($event){return _vm.willToForm(null)}},slot:"button"},[_vm._v("追加")]):_vm._e(),_c('b-table',{attrs:{"loading":_vm.loading || _vm.isLoading,"hoverable":"","data":_vm.tBonuses}},[_c('b-table-column',{attrs:{"label":"","width":"3em"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var index = ref.index;
return [_c('small',{staticClass:"has-text-grey"},[_vm._v(_vm._s(index + 1))])]}}])}),_c('b-table-column',{attrs:{"label":"管理番号","width":"6em"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.mInstructor)?_c('span',[_vm._v("#"+_vm._s(row.mInstructor.code))]):_c('soan')]}}])}),_c('b-table-column',{attrs:{"label":"氏名","width":"10em"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.mInstructor)?_c('span',[_vm._v(_vm._s(row.mInstructor.name))]):_c('soan')]}}])}),_c('b-table-column',{attrs:{"label":"エリア","width":"6em"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.area)?_c('span',[_vm._v(_vm._s(row.area.label))]):_c('soan')]}}])}),_c('b-table-column',{attrs:{"label":"備考"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.note))])]}}])}),_c('b-table-column',{attrs:{"label":"その他給","numeric":"","width":"8em"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.amount.toLocaleString())+"円")])]}}])}),_c('b-table-column',{attrs:{"custom-key":"actions","cell-class":"is-actions-cell","width":"4em","visible":_vm.editable},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"buttons is-right"},[_c('b-button',{attrs:{"type":"is-warning","size":"is-small","icon-left":"square-edit-outline"},on:{"click":function($event){return _vm.willToForm(row)}}})],1)]}}])}),_c('section',{staticClass:"section",attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[(_vm.loading || _vm.isLoading)?[_c('p',[_c('b-icon',{attrs:{"icon":"dots-horizontal","size":"is-large"}})],1),_c('p',[_vm._v("Fetching data...")])]:[_c('p',[_c('b-icon',{attrs:{"icon":"emoticon-sad","size":"is-large"}})],1),_c('p',[_vm._v("Nothing's here…")])]],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }