<template>
  <section class="section is-main-section" ref="top">
    <b-message
      type="is-info"
      v-if="tPaymentWork && tPaymentWork.fixStatus.is('Init')"
    >
      <div class="level">
        <div class="level-left">
          <p class="">
            {{
              tPaymentWork.versionAt.format('YYYY/MM/DD HH:mm:ss')
            }}に計算されました。
            <br />
            <span v-if="isAdminRole">
              未確定ステータスです。確定すると再計算不可になり、インストラクターに請求書の確認依頼をします。
            </span>
          </p>
        </div>
        <div class="level-right">
          <p class="buttons level-item" v-if="isAdminRole">
            <b-button
              type="is-success"
              icon-right="repeat"
              @click="willReCalcurate"
              outlined
              :loading="isLoading"
              >再計算する</b-button
            >
            <b-button
              type="is-warning"
              icon-right="stamper"
              @click="willComplete"
              >確定する</b-button
            >
          </p>
        </div>
      </div>
    </b-message>
    <b-message
      type="is-warning"
      v-else-if="tPaymentWork && tPaymentWork.fixStatus.is('Request')"
    >
      <div class="level">
        <div class="level-left">
          <p class="">
            {{
              tPaymentWork.versionAt.format('YYYY/MM/DD HH:mm:ss')
            }}に計算されました。
            <br />
            <span>
              確定(請求書確認中)ステータスです。インストラクターが請求書を確認しています。
            </span>
          </p>
        </div>
        <div class="level-right"></div>
      </div>
    </b-message>

    <div class="columns">
      <div class="column is-two-fifths" v-if="tPaymentWork">
        <CardWidget
          type="is-success"
          icon="account-multiple"
          :number="tPaymentWork.instructorCount"
          suffix="名"
          label="対象者数"
        />
        <Tiles>
          <CardWidget
            class="tile is-child"
            type="is-dark"
            icon="blank"
            :number="tPaymentWork.totalAmount"
            suffix="円"
            label="総計"
          />
          <CardWidget
            class="tile is-child"
            type="is-dark"
            icon="blank"
            :number="withholdingAmount"
            suffix="円"
            label="源泉徴収税額"
          />
        </Tiles>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim v-if="isAdminRole">
          <Tiles v-if="tPaymentWork" :maxPerRow="2">
            <b-field class="tile is-child" label="支払回数">
              <ValidationInput
                v-model="paymentWorkZipForm.payCnt"
                rules="required|integer|min_value:1|max_value:15"
                placeholder="1〜15"
              ></ValidationInput>
            </b-field>
            <b-field class="tile is-child" label="支給日">
              <ValidationInput
                v-model="paymentWorkZipForm.payDate"
                rules="required|length:8|regax_date"
                placeholder="YYYYMMDD"
              ></ValidationInput>
            </b-field>
            <b-button
              class="tile is-child"
              type="is-primary"
              expanded
              icon-left="download"
              @click="handleSubmit(willDownload)"
              >PCA CSVダウンロード</b-button
            >
          </Tiles>
        </ValidationObserver>
      </div>
      <div class="column is-three-fifths">
        <CardComponent class="has-table" title="エリア集計">
          <b-table :loading="isLoading" :data="tAreaSummarys">
            <b-table-column label="エリア" v-slot="{ row }">
              <span>{{ row.area.label }}</span>
            </b-table-column>
            <b-table-column label="実績数" v-slot="{ row }" numeric>
              <span>{{ row.totalCount }}</span>
            </b-table-column>
            <b-table-column label="時間給" v-slot="{ row }" numeric>
              <span>{{ row.baseAmount.toLocaleString() }}</span>
            </b-table-column>
            <b-table-column label="各種手当" v-slot="{ row }" numeric>
              <span>{{ row.instillAmount.toLocaleString() }}</span>
            </b-table-column>
            <b-table-column label="固定額" v-slot="{ row }" numeric>
              <span>{{ row.fixedAmount.toLocaleString() }}</span>
            </b-table-column>
            <b-table-column label="その他給" v-slot="{ row }" numeric>
              <span>{{ row.bonusAmount.toLocaleString() }}</span>
            </b-table-column>
            <b-table-column label="合計" v-slot="{ row }" numeric>
              <span>{{ row.totalAmount.toLocaleString() }}円</span>
            </b-table-column>
            <section class="section" slot="empty">
              <div class="content has-text-grey has-text-centered">
                <template v-if="isLoading">
                  <p>
                    <b-icon icon="dots-horizontal" size="is-large" />
                  </p>
                  <p>Fetching data...</p>
                </template>
                <template v-else>
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>Nothing's here&hellip;</p>
                </template>
              </div>
            </section>
          </b-table>
        </CardComponent>
        <div class="buttons is-pulled-right">
          <b-button
            @click="scrollToAnchorPoint('bottom')"
            type="is-info"
            icon-left="arrow-collapse-down"
            outlined
          >
            <span>その他給設定へ移動</span>
          </b-button>
        </div>
      </div>
    </div>
    <WorkInstructorsCard
      :loading="isLoading"
      :tPaymentWork="tPaymentWork"
      :tInstructorSummarys="tInstructorSummarys"
    />
    <BonusCard
      :loading="isLoading"
      :paymentYm="tPaymentWork.paymentYmKey"
      :editable="tPaymentWork.fixStatus.is('Init')"
      v-if="tPaymentWork"
    />
    <div class="level" ref="bottom">
      <div class="level-left">
        <div class="level-item">
          <b-dropdown position="is-top-right" :close-on-click="false">
            <template #trigger="{ active }">
              <b-button
                label="明細CSVダウンロード"
                icon-left="cloud-download"
                :icon-right="active ? 'menu-down' : 'menu-up'" />
            </template>
            <b-dropdown-item  @click="willCsvDownload('row')">
              <span class="icon-text"><b-icon icon="file-document-box-outline" />レッスンフィー</span>
            </b-dropdown-item>
            <b-dropdown-item separator />
            <b-dropdown-item  @click="willCsvDownload('bonus')">
              <span class="icon-text"><b-icon icon="file-document-box-outline" />その他給</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-button
            @click="scrollToAnchorPoint('top')"
            type="is-info"
            icon-left="arrow-collapse-up"
            outlined
          >
            <span>上へ戻る</span>
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { ApiPaymentWork } from '@/api'
import { Collection } from '@/util'
import Tiles from '@/components/Tiles.vue'
import CardWidget from '@/components/CardWidget.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import BonusCard from '@/components/Payment/BonusCard.vue'
import WorkInstructorsCard from '@/components/Payment/WorkInstructorsCard.vue'

export default {
  mixins: [BaseMixin],
  components: { CardComponent, Tiles, CardWidget, BonusCard, WorkInstructorsCard },
  props: {
    pathPaymentYm: String
  },
  computed: {
    ...mapState('storePayment', ['paymentWorkZipForm']),
    ...mapGetters('storeAuth', ['isAdminRole']),
    withholdingAmount () {
      return this.tInstructorSummarys.reduce((sum, row) => sum + row.withholdingAmount, 0)
    }
  },
  data () {
    return {
      tPaymentWork: null,
      tInstructorSummarys: [],
      tAreaSummarys: [],
    }
  },
  methods: {
    ...mapActions('storePayment', ['createPaymentWork', 'completePaymentWork']),
    forgeData () {
      this.isLoading = true
      ApiPaymentWork.readPaymentWork(this.pathPaymentYm).then(({ tPaymentWork, tInstructorSummarys, tAreaSummarys }) => {
        this.tPaymentWork = tPaymentWork
        this.tInstructorSummarys = new Collection(tInstructorSummarys).sort('code', 'asc').members
        this.tAreaSummarys = tAreaSummarys
        this.isLoading = false
      }).catch(this.handleError)
    },
    willBackSoftCheckin () {
      this.$router.replace({ name: 'SoftCheckins', params: { pathPaymentYm: this.pathPaymentYm } })
    },
    willReCalcurate () {
      console.log('willReCalcurate')
      this.isLoading = true
      setTimeout(() => {
        this.createPaymentWork(this.pathPaymentYm).then(() => {
          this.forgeData()
          this.alertSuccess('再計算しました')
        }).catch(this.handleError)
      }, 300)
    },
    willComplete () {
      this.$buefy.dialog.confirm({
        message: '計算結果を確定しますか？',
        onConfirm: () => {
          this.isLoading = true
          setTimeout(() => {
            this.completePaymentWork(this.tPaymentWork.paymentYmKey).then(() => {
              this.alertSuccess('計算結果を確定しました')
              this.$router.replace({ name: 'Payment' })
            }).catch(this.handleError)
          }, 800)
        }
      })
    },
    willDownload () {
      console.log('willDownload')
      this.$buefy.snackbar.open({
        message: '計算結果ZIPのダウンロードを開始しました。',
        type: 'is-success',
        position: 'is-bottom-left',
        duration: 3000
      })

      const link = document.createElement('a')
      this.paymentWorkZipForm.paymentYm = this.pathPaymentYm
      link.href = ApiPaymentWork.paymentWorkZipUri(this.paymentWorkZipForm)
      link.click()

      this.paymentWorkZipForm.reset()
      this.$refs.observer.reset()
    },
    scrollToAnchorPoint (refName) {
      if (refName === 'top') {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      const el = this.$refs[refName]
      el.scrollIntoView({ behavior: 'smooth' })
    },
    willCsvDownload (type) {
      console.log('willCsvDownload')
      this.$buefy.snackbar.open({
        message: '明細CSVのダウンロードを開始しました。',
        type: 'is-success',
        position: 'is-bottom-left',
        duration: 3000
      })
      const link = document.createElement('a')
      link.href = ApiPaymentWork.forgePaymentCsvUri(this.pathPaymentYm, type)
      link.click()
    },
  },
  created () {
    this.isLoading = true
  },
  mounted () {
    this.forgeData()
  },
}
</script>
